/* main {
	margin: 0 auto;
	max-width: 100%;
  padding: 50px 30px;
} */
:root {
  --primary: #429e8f;
  --secondary: #3f3e3d;
  --tertiary: #2d7fe8;
  --light: #ffffff;
  --dark: #000000;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

} 

.primary {
  color: var(--primary);
}

* {
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100%;
}

body {
  
  font-weight: regular;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: var(--light);
  color: var(--dark);
  
  font-family: 'Roboto', sans-serif;
 
} 


h1,
h2,
h3,
h4,
h5,
h6 { 
  /* font-family: 'Poiret One', cursive;  */
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--secondary);
  text-decoration: none; 
  margin-left: 20px;
    padding: 0PX;
    font-size: 18px;
}

a:hover {
  text-decoration: none; 
  color: var(--primary);
}

nav {
  display: flex; 
  justify-content: space-between;
  flex-wrap: wrap; 
  width: 100%; 
  background-color: white;
  position: sticky;
  position: -webkit-sticky; 
  top: 0;
  padding: 20px; 
  box-shadow: 0 4px 7px rgba(0, 0, 0, .5); 
  padding-left: 50px; 
  padding-right: 50px;  
  z-index: 22;
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 2rem;
  border-radius: .5rem;
  border: 2px;
  border-style: solid;
  width: 20rem;
  cursor: pointer; 
  border-color: var(--primary); 
}

.btn-S {
  width: 9rem;
} 

.btn-alt{
  border-color: var(--primary);  
  color: var(--primary);  
  background-color: #FFFFFF; 
}

/* button:hover {
  opacity: .8;
} */

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

.altbackground {
  background-color: var(--secondary);
}

nav ul {
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
  list-style: none;  
  justify-content: center;
 

} 

nav a:hover {
  text-decoration: none;
}

nav  li {
  cursor: pointer;
}

textarea {
	width: 100%;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
} 

.container-small{ 
  width: 35%;
 
}

.flex-row {
  display: flex;
  /* flex-wrap: wrap; */
}

.space-between {
  justify-content: space-between;
} 

.space-around { 
  justify-content: space-around;
}

.space-evenly{
  justify-content: space-evenly;
}
.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
} 

.mx-5 {
  margin-right: var(--spacing-five);
  margin-left: var(--spacing-five);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
} 

.text-center 
{ 
  width: 100%;  
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
} 

.test {
  background-color: red;
} 
.test2 {
  background-color: blue;
}

button {
	background: var(--primary);
	border-radius: 8px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	padding: 10px 15px;	
}

a {
	border-radius: 5px;
	color: var(--secondary);
	font-weight: 400;
	padding: 2px 4px;
	margin-left: -2px;
	margin-right: -2px;
	
}  

.align-items-center{
  align-items: center;
}

.column { 
  display: flex; 
  flex-direction: column;
} 



.center { 
  align-content: center; 
  justify-content: center; 
  align-items: center;

}

.navActive {
  color: var(--primary);
  font-weight: bold;
} 

.full { 
  width:100%;
} 


.margintop {
  margin-top: 32px;
} 

.padding-top{
  padding-top: 90px;
} 



#alt { 
  border-color: white;  
  color: white;  
  background-color: transparent;
} 

#alt:hover {
  background-color: var(--primary); 
  color: white;
}

.reghover:hover{
  opacity: .8;
} 

.althover:hover{
  opacity: .6; 
  background-color: var(--primary); 
  color: var(--light);
}
#grovealt { 
  
  border-color: var(--tertiary);  
  color: var(--tertiary);  
  background-color: #FFFFFF; 
}  

#grovereg { 
  background-color: var(--tertiary);  
  color: white; 
  border-color: var(--tertiary); 
}

#grovealt:hover { 
  background-color: var(--tertiary);  
  opacity: .6;
  color: white;
}

/* buttons end */ 

 .w-20{
    width: 20%;
 } 

 .w-10{
  width: 10%;
} 

.web-none {
  display: none;
}



.homemobile2 { 
  width: 35%;  
  height: 400px;
    
  
}
.homemobile2 img { 
  width: 100%; 
  border-width: 1px; 
  border-color: #000000;
  border-style: solid; 
  height: 100%;
} 

#weather {
  height: fit-content;
}

.hometext2 { 
  line-height: 1.5;
  width: 45%; 
  text-align: left;    
  color:black;  
  z-index: 3; 
  display: flex;  
  flex-direction: column;
  align-content: center; 
  justify-content: space-around; 
}
.main1 { 
  display: flex; 
  display: flex wrap; 
  padding-left: 150px; 
  padding-right: 150px;
  justify-content: space-between;  
  margin-top: 75px; 
 /* align-content: center;  why does this code not center my divs? */ 
  align-items: center;
  width:100%; 
} 

.main { 
  margin-top: 50px; 
  padding-left: 150px; 
  padding-right: 150px;
}


section {
  padding: 40px;
} 

.icon { 
    height: 30px; 
    width: 30px;
} 

.icon img { 
    width: 30px; 
}

.main2{  
    display: flex; 
    align-content: center; 
    justify-self: center; 
  
} 

/* footer begins */

footer { 
    height:150px; 
    background-color: var(--secondary); 
    width: 100%;
    display: flex; 
    flex-wrap: wrap; 
    align-content: center; 
    justify-content: center;   
    margin-top: 75px;
    color: white;
    

} 


.row-reverse{
  flex-direction: row-reverse;
}

.hero { 
  background-image: url("./assets/homepage/0.jpeg"); 
  background-attachment: fixed;  
  background-position: 80%;     
  background-size: cover; 
  z-index: 1; 
  padding: 0;
}  

#overlay {
  /* position: fixed;  Sit on top of the page content */
  /* display: none;  Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  padding: 60px;
  background-color: rgba(0,0,0, 0.3); /* Black background with opacity */
  z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
}

.hometext { 
   line-height: 1.5;
   width: 40%; 
   text-align: left;  
   display: flex; 
   flex-direction: column; 
   justify-content: space-evenly;
   height: 350px; 
   margin-left: 5%;
   color:white;  
   z-index: 3; 
} 


#Grove { 
  color: var(--secondary-color); 
}

#Grove-images { 
  display: flex; 
  align-content: center; 
  justify-content: center; 

}

#Grove-images img { 
  width: 60%; 
  border-width: 1px;
  border-color: #000000;
  border-style: solid;
} 

.menuNav.showMenu {
  width: 100%;
} 

.hometext1 { 
  line-height: 1.5;
  width: 100%; 
  text-align: left;  
  display: flex;
  margin-top: 25px;  
  color:black;  
  z-index: 3;   
  flex-direction: column; 
  justify-content: space-around;

}  

@media screen and (max-width: 980px) {  
  

   .hometext { 
       width: 60%; 
   }
   form {
    width: 60%;
  } 

    button{ 
        width: 15rem;
    }   

    #Grove-images { 
      width: 100%; 
  } 

  #Grove-images img { 
      width: 100%; 
  }



.main1 { 
  flex-direction: column; 
  justify-content: left;  
  padding: 25px; 
  margin-left: 0;
  
} 

.hometext2 {  
  width: 80%;
  order: 2; 
  text-align: left; 
  justify-content: left; 
  margin: 25px; 
} 

.homemobile2 { 
  width: 50%; 
  flex: 1 100%; 
} 

}

@media screen and (max-width: 768px) {  
  
 
  

    /* .hometext { 
        align-content: center; 
        justify-content: center; 
        align-items: center; 
    }  */

    

    

  
} 


@media screen and (max-width: 575px) {  



  .hero { 
    
    background-attachment: inherit;
    background-position: center; 
   
  }   

  /* .hero {
    background-size: 100% 100%
  } */


  .menubar{
    background: transparent;;
  border: none; 
  z-index: 10; 
  padding: 0; 
  text-align: left; 
  width: 30px; 
  height: 30px;
  }
  .mobile-show {
    display:flex;
  } 
  .mobile-hide{
    display: none;
  }
form {
  width: 80%;
}
   .hometext { 
       width: 95%;
       justify-self: center;
   }   

   .hometext1{
     width:100%;
   } 
   .hometext2{
     width: 100%;
   }

   /* .hometext1{
     text-align: left;
   } */
.main{
  padding: 25px;
  width: 100%;
} 

p {
  width: 100%;
}
   .menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: rgb(44, 44, 44);
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: 100%;
    z-index: 9;
  }


  .sidenav {
    display: block;
    padding: 10px 40px;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  } 

  .homemobile2{
    width:100%;
  } 
  .buttonmobile {
    width: 9rem; 
    margin: calc(.5rem)
  }  
  #grovealt{
    margin-left: 0;
  } 

  h1 {
    font-size: 1.5rem;
}
} 